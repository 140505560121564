import { DataGrid } from '@mui/x-data-grid';
import {Breadcrumb} from 'react-bootstrap';
import PresentationsIcon from './PresentationsIcon.png';
import {useTheme} from "../../../ThemeProvider";

export default function Presentations() {
    const { fontColor } = useTheme(); // Use the bgColor from the context

    return (
        <>
        <Breadcrumb style={{zIndex:"9",color:"white"}}>
            <Breadcrumb.Item href="/" style={{zIndex:"9"}}>Home</Breadcrumb.Item>
            <Breadcrumb.Item active style={{zIndex:"9",color:"white"}}>発表 Presentations</Breadcrumb.Item>
        </Breadcrumb>

        <h1 className='page-type-name'>PRESENTATIONS</h1>

        <div className='about-me-title-area-container'>
            <img className='about-me-img' src={ PresentationsIcon } alt="So Shimizu" style={{backgroundColor:"white"}}/>
            <div className='about-me-title-area'>
                <h1 className='page-subtitle'>学会・セミナー等の発表</h1>
                <h1 className='page-title'>PRESENTATIONS</h1>
                <br/>
                <p>
                    <b>～Current status～</b><br/>
                    Total：17
                </p>
            </div>
        </div>
        <p>
            学会やセミナーにおける発表をテーブル形式でリスト化しています。
            テーブルのフィールド名部分をクリックするとそのカラムを並べ替えすることが出来ます。
            さらに、フィールド名の右端にあるボタンを展開すると、並び替えの方法の選択や表示検索フィルター、表示するカラムの設定なども可能です。
            尚、IDは主キーとして発表順に振ってあるだけなので深い意味はありません。
        </p>

        <div style={{ height: '660px', width: '100%' }}>
            <h2 className='section-title-sub'>リスト List</h2>
            <DataGrid
                style={{
                    color:fontColor,
                    fontSize:'18px',
                }}
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
            />
        </div>

        <br/>
        <br/>
        <br/>

        </>
    );
};

const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'presenter', headerName: 'Presenter', width: 430 },
    { field: 'title', headerName: 'Title', width: 1100 },
    { field: 'presentationName', headerName: 'Conference etc.', width: 330 },
    { field: 'locality', headerName: 'Locality', width: 290 },
    { field: 'date', headerName: 'Date.', width: 120 },
    { field: 'type', headerName: 'Type', width: 80 },
    { field: 'Remarks', headerName: 'Remarks', width: 180 },
];

const rows = [
    {
        id: 1, presenter:'〇清水壮, 渡辺恭平',
        title: '日本から新たに発見されたオナガアメバチモドキ属Weisia亜属の分類学的研究（ハチ目：ヒメバチ科：ハバチヤドリヒメバチ亜科）',
        presentationName: '日本昆虫学会第74回大会',
        locality: '広島大学', date: '2014/9', type:'oral', Remarks:'',
    },
    {
        id: 2, presenter:'〇清水壮, 渡辺恭平',
        title: '日本から新たに発見されたオナガアメバチモドキ属Weisia亜属の分類学的研究（ハチ目：ヒメバチ科：ハバチヤドリヒメバチ亜科）',
        presentationName: '第35回菅平動物学セミナー',
        locality: '筑波大学菅平高原実験センター', date: '2014/11', type:'oral', Remarks:'',
    },
    {
        id: 3, presenter:'〇清水壮, 渡辺恭平',
        title: '日本から新たに発見されたアメバチ亜科Skiapus属の分類学的研究',
        presentationName: '日本昆虫学会関東支部第51回大会',
        locality: '東京農業大学', date: '2014/12', type:'oral', Remarks:'',
    },
    {
        id: 4, presenter:'〇清水壮',
        title: '日本におけるヒメバチ科寄生蜂の分類研究の現状と今後の展望',
        presentationName: '日本昆虫学会第75回大会',
        locality: '九州大学', date: '2015/9', type:'oral', Remarks:'招待あり',
    },
    {
        id: 5, presenter:'〇清水壮, 渡辺恭平',
        title: '台湾から新たに発見されたウスモンアメバチ属Leptophionの未記載種について',
        presentationName: '日本昆虫学会関東支部第52回大会',
        locality: '玉川大学', date: '2015/12', type:'oral', Remarks:'',
    },
    {
        id: 6, presenter:'〇清水壮, 伊藤誠人, Bennett A, 前藤薫',
        title: '日本産Therion属（ハチ目：ヒメバチ科：コンボウアメバチ亜科）の分類学的研究',
        presentationName: '第37回菅平動物学セミナー',
        locality: '筑波大学菅平高原実験センター', date: '2016/12', type:'oral', Remarks:'',
    },
    {
        id: 7, presenter:'〇清水壮',
        title: '日本産Stauropoctonus属（ハチ目，ヒメバチ科，アメバチ亜科）の分類学的研究',
        presentationName: '日本昆虫分類学会第19回大会',
        locality: '愛媛大学', date: '2016/12', type:'oral', Remarks:'',
    },
    {
        id: 8, presenter:'〇清水壮, 伊藤誠人, BennettA, 前藤薫',
        title: '日本産Therion属（ハチ目：ヒメバチ科：コンボウアメバチ亜科）の系統分類学的研究',
        presentationName: '日本昆虫学会近畿支部大会',
        locality: '大阪市立自然史博物館', date: '2016/12', type:'oral', Remarks:'',
    },
    {
        id: 9, presenter:'清水壮, 〇小西和彦',
        title: '徳島県で得られたオナガアメバチ属の1新種（膜翅目，ヒメバチ科，ハバチヤドリヒメバチ亜科）',
        presentationName: '日本昆虫学会四国支部第56回大会',
        locality: 'ラ・フォーレつるぎ山', date: '2017/7', type:'oral', Remarks:'',
    },
    {
        id: 10, presenter:'〇清水壮, Bennett A, 前藤薫',
        title: '日本未記録族Idiogrammatini族（ハチ目：ヒメバチ科：ハバチヤドリヒメバチ亜科）の系統分類学的研究',
        presentationName: '日本昆虫学会第78回大会',
        locality: '名城大学', date: '2018/9', type:'oral', Remarks:'',
    },
    {
        id: 11, presenter:'〇清水壮',
        title: 'アメバチ属Ophionの系統分類学的研究（ハチ目： ヒメバ科：アメバチ亜科）',
        presentationName: '日本昆虫分類学会第21回大会',
        locality: '愛媛大学', date: '2018/12', type:'oral', Remarks:'',
    },
    {
        id: 12, presenter:'〇藤江隼平, 清水壮, 刀禰浩一, 松尾和典, 前藤薫',
        title: 'コマユバチは星型の繭塊をどのように吊り下げるか？',
        presentationName: '日本昆虫学会第81回大会',
        locality: '法政大学・オンライン', date: '2021/9', type:'oral', Remarks:'',
    },
    {
        id: 13, presenter:'〇清水壮',
        title: '記載論文の効率的な執筆と投稿戦略',
        presentationName: '日本昆虫学会第81回大会 小集会「昆虫分類学若手懇談会シンポジウム『若手分類学者の生き残りシンポジウム』」',
        locality: '法政大学・オンライン', date: '2021/9', type:'oral', Remarks:'招待あり',
    },
    {
        id: 14, presenter:'徳平拓朗, 小野祥児, 清水壮, 横山岳, 〇前藤薫',
        title: 'クワコとカイコの幼虫に内部寄生する新種サムライコマユバチの発見',
        presentationName: '日本応用動物昆虫学会第66回大会',
        locality: 'オンライン', date: '2022/3', type:'oral', Remarks:'',
    },
    {
        id: 15, presenter:'〇清水壮, 前藤薫',
        title: '日本産ホシアメバチ属（ハチ目: ヒメバチ科：アメバチ亜科）の系統分類学と種多様性',
        presentationName: '日本昆虫学会第82回大会',
        locality: '信州大学', date: '2022/9/4', type:'poster', Remarks:'',
    },
    {
        id: 16, presenter:'〇So Shimizu',
        title: 'Ichneumonoidea Web Database',
        presentationName: 'Darwin wasp seminar',
        locality: 'online', date: '2023/3/3', type:'oral', Remarks:'招待あり',
    },
    {
        id: 17, presenter:'〇So Shimizu, Gavin R. Broad, Kaoru Maeto',
        title: 'Multilocus molecular phylogenetic assessment of species-groups of the Darwin wasp genus Enicospilus Stephens (Hymenoptera: Ichneumonidae: Ophioninae)',
        presentationName: '10th congress of international society of Hymenopterists',
        locality: 'Iasi, Romania', date: '2023/7/25', type:'oral', Remarks:'',
    },
    {
        id: 18, presenter:'〇小西和彦, 清水　壮, 青柳正人',
        title: '日本産ミズバチ属（ハチ目ヒメバチ科）の分類',
        presentationName: '水生昆虫研究会',
        locality: 'NA', date: '2025', type:'oral', Remarks:'',
    },
];



